export default {
  "affordable.package.offer": "Affordable package offer",
  "customize.package": "Customize your own package",
  "amount": "Amount",
  "customizable.box.reservations": "If you don't want a ready made package, you can customize the number of boxes and the duration of the reservation yourself.",
  "number.of.moving.boxes": "Number of moving boxes",
  "reserve": "Reserve",
  "select.rental.end.date": "Select rental end date",
  "total.amount": "Total amount",
  "make.a.payment": "Make a Payment",
  "pickup": "Pickup",
  "box.pickup.or.delivery": "Choose whether you pick up the moving boxes yourself or have them delivered to you",
  "delivery.service": "Delivery service",
  "transport.fee": "transport fee",
  "pick.up.myself": "I'll pick up myself",
  "rental.start.date": "Rental start date",
  "start.time": "Start time",
  "clock": "clock",
  "open.mon.fri": "Open Mon-Fri",
  "july": "July",
  "delivery.address.specification": "Street address and house number to which the moving boxes will be delivered",
  "entrence.door.number": "Entrence and door number",
  "more.information": "More information",
  "esim": "e.g",
  "door.code": "door code",
  "backwards": "Backwards",
  "forward": "Forward",
  "personal.information": "Personal information",
  "first.name": "First name",
  "last.name": "Last name",
  "telephone.number": "Telephone number",
  "return": "Return",
  "self.return.or.pickup": "Choose whether you return it yourself or have the moving boxes picked up from you",
  "rental.end.date": "Rental end date",
  "pickup.address.specification": "Street address and house number where we pick up the moving boxes",
  "successfully": "Successfully",
  "We received your payment": "We received your payment",
  "thank.you.message": "Thank you for choosing us",
  "go.to.home.page": "Go to the home page",
  "order.confirmation.reminder": "The order has been sent by e-mail BEWARE OF SPAM. Confirm your order within 2 hours. The order expires after 2 hours if it has not been confirmed",
  "thank.you": "Thank you",
  "moving.box.size": "Muuttolaatikko koko",
  "delivery": "Delivery",
  "delivery.address.provided": "The street address where the moving boxes will be delivered",
  "pickup.address.provided": "Street address where we pick up the moving boxes",
  "moving.boxes.rental": "Moving boxes rental",
  "delivery.of.moving.boxes": "Delivery of moving boxes",
  "collection.of.moving.boxes": "Collection of moving boxes",
  "amount.to.be.paid": "Amount to be paid",
  "discount.price": "Discount Price"
};
